import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/04 Templates/layout'

export const query = graphql`
query SuccessPageQuery {
    prismic {
        allContact_forms {
          edges {
            node {
              _meta {
                uid
                type
              }
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }  
              title
              form_fields {
                type
                label
              }
            }
          }
        }
      }
    }
`
 
  const RenderBody = ({ home, slices }) => (
    <React.Fragment>
        <div className="wrapper">
            <article>
                <div className="content content--form">
                    <div className="content">
                        <p>Thank you for getting in touch. We will get back to you as soon as possible</p>
                    </div>
                </div>
            </article>
        </div>
    </React.Fragment>
  );
  
  const Contact = data => {
    const doc = data.data.prismic.allContact_forms.edges[0];
    if(!doc) return null;
    return(
      <Layout data={doc}>
          <RenderBody home={doc}/>
      </Layout>
    )
  }
  
  export default Contact;